$(document).on("ready page:load", function () {
  $(".animate-link-to").each((i, el) => {
    const linkTo = $(el).data("link-to");
    $(el).click(() => {
      const currentPosition = $(window).scrollTop();
      const targetPosition = $(linkTo).offset().top;

      let duration = 1000;

      // if target is not so far...
      if (targetPosition - currentPosition < 500) {
        // more faster transition.
        duration = 500;
      }

      $("body").animate({ scrollTop: targetPosition }, duration, () => {
        location.hash = linkTo;
      });
    });
  });
});

var modal;
if ($(".modal-lineyoyaku").length > 0) {
  if (window.sessionStorage.getItem("lineyoyakuModalFlag") != "closed") {
    modal = $(".modal-lineyoyaku");

    modal.delay(500).animate({ "right": 0 }, 1500);

    $(modal.find(".close")).on("click", function () {
      window.sessionStorage.setItem("lineyoyakuModalFlag", "closed");
      modal.hide();
    });
  }
}
if ($(".modal-midwifeyoyaku").length > 0) {
  if (window.sessionStorage.getItem("midwifeyoyakuModalFlag") != "closed") {
    modal = $(".modal-midwifeyoyaku");

    modal.delay(500).animate({ "right": 10 }, 1500);

    $(modal.find(".close")).on("click", function () {
      window.sessionStorage.setItem("midwifeyoyakuModalFlag", "closed");
      modal.hide();
    });
  }
}

if ($(".side-menu-wrapper").length > 0) {
  var wrapper = $(".side-menu-wrapper");
  var menu = $(".side-menu");
  var plus = $(".plus");
  var minus = $(".minus");
  var menu_bg = $(".menu-background");
  var mypage_contents = $(".mypage-menu-list");
  var body = $("body");
  var overlay = $(".menu-cover");
  var mypage_contents_item = $(".mypage-menu-item");
  var menu_item = $(".side-menu-item");
  var obstract_log = $(".focus_ga-logger");
  var close_button = $(".close");
  var drawer = $(".drawer");
  var side_menu_toggle = $(".side_menu_toggle");


  // aタグなどデフォルトでフォーカス当たる要素を最初にフォーカスできないようにする。
  menu_item.find("a").attr("tabindex", "-1");
  mypage_contents_item.find("a").attr("tabindex", "-1");
  obstract_log.attr("tabindex", "-1");

  // drawerは逆に最初にフォーカスを付与する
  drawer.attr("tabindex", "0");

  $(wrapper.find(".drawer")).on("click keydown", function (e) {
    if (e.type === "click" || e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      menu.animate({ "right": 0 }, 500);
      menu_bg.show();
      overlay.show();
      body.css({ height: "100%", overflow: "hidden" });

      // メニューを開いた際にリストにフォーカスを設定する
      side_menu_toggle.attr("tabindex", "0");
      menu_item.not(":first").find("a").attr("tabindex", "0");
      obstract_log.attr("tabindex", "0");
      close_button.attr("tabindex", "0");

      // マイページのトグルを開いたままメニューを閉じていた場合、再度メニューを開いた際にマイページのリストにもフォーカスを付与する
      if ($(".mypage-menu-list").hasClass("open")) {
        mypage_contents_item.find("a").attr("tabindex", "0");
      } else {
        mypage_contents_item.find("a").attr("tabindex", "-1");
      }
      $(this).focus(); // フォーカスを維持
    }
  });

  $(wrapper.find(".close-menu")).on("click keydown", function (e) {
    if (e.type === "click" || e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      menu.animate({ "right": -500 }, 500);
      menu_bg.hide();
      overlay.hide();
      body.css({ height: "", overflow: "" });

      // メニューを閉じたときにフォーカスを外す
      side_menu_toggle.attr("tabindex", "-1");
      menu_item.find("a").attr("tabindex", "-1");
      obstract_log.attr("tabindex", "-1");
      close_button.attr("tabindex", "-1");
      mypage_contents_item.find("a").attr("tabindex", "-1");
      $(this).focus(); // フォーカスを維持
    }
  });

  $(wrapper.find(".menu-cover")).on("click", function () {
    menu.animate({ "right": -500 }, 500);
    menu_bg.hide();
    overlay.hide();
    body.css({ height: "", overflow: "" });

    // メニューを閉じたときにフォーカスを外す
    side_menu_toggle.attr("tabindex", "-1");
    menu_item.find("a").attr("tabindex", "-1");
    obstract_log.attr("tabindex", "-1");
    close_button.attr("tabindex", "-1");
    mypage_contents_item.find("a").attr("tabindex", "-1");
  });

  // サイドメニューのアコーディオンを開閉
  $(wrapper.find(".side_menu_toggle")).on("click keydown", function (e) {
    if (e.type === "click" || e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      if (plus.hasClass("accordion-on")) {
        plus.removeClass("accordion-on").addClass("accordion-off");
        minus.removeClass("accordion-off").addClass("accordion-on");
        mypage_contents.addClass("open");
        // マイページを開いた際にリストにフォーカスを設定する
        mypage_contents_item.find("a").attr("tabindex", "0");
      } else {
        plus.removeClass("accordion-off").addClass("accordion-on");
        minus.removeClass("accordion-on").addClass("accordion-off");
        mypage_contents.removeClass("open");
        // マイページを閉じた際にリストのフォーカスを外す
        mypage_contents_item.find("a").attr("tabindex", "-1");
      }
      $(this).focus(); // フォーカスを維持
    }
  });
}

if ($(".faq_item").length > 0) {
  var faqItems = $(".faq_item");
  // QAトグル内のaタグのリンクを初期無効にする。
  $(faqItems).find("a").attr("tabindex", "-1");

  faqItems.each(function (index, element) {
    var toggle = $(element).find(".faq_item-toggle");
    var faq_plus = $(element).find(".faq_item-plus");
    var faq_minus = $(element).find(".faq_item-minus");
    var faq_contents = $(element).find(".faq_item-contents");
    $(toggle).on("click", function () {
      if (faq_plus.hasClass("accordion-on")) {
        faq_plus.removeClass("accordion-on").addClass("accordion-off");
        faq_minus.removeClass("accordion-off").addClass("accordion-on");
        faq_contents.addClass("open");
        // トグルを開いたらaタグのフォーカスを付与
        $(faqItems).find("a").attr("tabindex", "0");
      } else {
        faq_plus.removeClass("accordion-off").addClass("accordion-on");
        faq_minus.removeClass("accordion-on").addClass("accordion-off");
        faq_contents.removeClass("open");
        // トグルを閉じたらaタグのフォーカスを削除
        $(faqItems).find("a").attr("tabindex", "-1");
      }
    });
  });
}

$(function () {
  if ($(".infiniteslide").length > 0) {
    $(".infiniteslide").infiniteslide({
      "speed": 30,          // 速さ 単位はpx/秒です。
      "direction": "left",  // up/down/left/rightから選択
      "pauseonhover": true, // マウスオーバーでストップ
      "responsive": false,  // 子要素の幅を%で指定しているとき
      "clone": 3            // 子要素の複製回数
    });

    // フォーカスが当たった時にマウスオーバー状態にしてスライドを停止
    $(".infiniteslide_focus").on("focus", function () {
      $(this).closest(".infiniteslide").trigger("mouseenter");
    });

    // フォーカスが外れた時にマウスオーバー状態を解除してスライドを再開
    $(".infiniteslide_focus").on("blur", function () {
      $(this).closest(".infiniteslide").trigger("mouseleave");
    });
  }
});

$(function() {
  $(window).on("load scroll resize", function() {
    const spFixedFooterBtn = $(".js_spFixedFooterButton");
    spFixedFooterBtn.hide();
    if (window.matchMedia("(max-width:960px)").matches && $(this).scrollTop() > 100) {
      spFixedFooterBtn.show();
    } else {
      spFixedFooterBtn.hide();
    }
  });
});
